import { object, func, array, string } from 'prop-types'
import React from 'react'
import { FormattedMessage, intlShape } from '../../../util/reactIntl'
import css from './Spaces.module.css'
import { formatMoney } from '../../../util/currency';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;


function formatString(str) {
    return str.replace(/_/g, ' ').replace(/-/g, ' ').replace(/\b\w/g, function (match) {
        return match.toUpperCase();
    });
};

function sortSpaces(spaces) {
    // Filter spaces with default true or find general space if no default is set
    const defaultSpace = spaces.find(space => space.default && space.default.length);

    const generalSpace = spaces.find(s => s.sleeping === 'general');
    const earlySpace = spaces.find(s => s.sleeping === 'earlyBird');
    const earlySpaceMaybe = earlySpace ? [earlySpace] : [];
    const generalSpaceMaybe = generalSpace ? [generalSpace] : [];

    // Filter spaces without default
    const otherSpaces = defaultSpace
        ? spaces.filter(space => !space?.default?.length)
        : spaces.filter(space => !space?.default?.length && !['general', 'earlyBird'].includes(space.sleeping))

    // console.log(otherSpaces, '&&& &&& => otherSpaces');


    // Sort otherSpaces by price in ascending order
    otherSpaces.sort((a, b) => a.price.amount - b.price.amount);

    // Concatenate default space and other spaces
    return defaultSpace
        ? [defaultSpace, ...otherSpaces]
        : [...earlySpaceMaybe, ...generalSpaceMaybe, ...otherSpaces];
}

const SpaceCard = (props) => {
    const { intl, selectedSpace, setSelectedSpace, spaces } = props;
    const { available, sleeping, space, price, id } = props.space;


    const sleepingLabelMaybe = !!sleeping ? <p className={css.sleeping}>{formatString(sleeping)}</p> : null;
    const spaceLabelMaybe = !!space ? <span className={css.spaceType}>{formatString(space)}</span> : null;
    const priceLabel = formatMoney(intl, new Money(price.amount, price.currency));

    const isEarlyBirdSpace = sleeping === 'earlyBird';
    const generalSpace = spaces.find(s => s.sleeping === 'general');
    let discountLabel = null;

    if (isEarlyBirdSpace && generalSpace) {
        const generalSpacePrice = generalSpace.price;
        const earlyBirdSpacePrice = price;
        const difference = generalSpacePrice.amount - earlyBirdSpacePrice.amount;
        const formattedDiscount = formatMoney(intl, new Money(difference, price.currency));

        discountLabel = difference > 0
            ? <i className={css.discountLabel}>{`Save ${formattedDiscount} per night`}</i>
            : null;
    };

    const notAvailable = !available;

    const notAvailableLabelMaybe = notAvailable ? (<div className={css.box}>
        <div className={`${css["wdp-ribbon"]} ${css["wdp-ribbon-two"]}`}><span><FormattedMessage id='SpaceCard.notAvailableLabel' /></span></div>
    </div>) : null;

    const spaceCardClasses = selectedSpace === id && available > 0
        ? classNames(css.active, css.space)
        : !available
            ? classNames(css.disabled, css.space)
            : css.space;



    return (
        <div className={spaceCardClasses} onClick={() => available > 0 ? setSelectedSpace(id) : null}>
            {notAvailableLabelMaybe}
            <p className={css.price}>{priceLabel}</p>
            {sleepingLabelMaybe}
            {spaceLabelMaybe}
            {discountLabel}
        </div>
    )
};


export default function Spaces(props) {
    const { spaces = [], className, ...rest } = props;

    const sortedSpaces = sortSpaces(spaces);
    const classes = classNames(css.spacesContainer, className)

    return (
        <div className={classes}>
            {sortedSpaces.map((s) => (
                <SpaceCard key={s.id} space={s} spaces={spaces} {...rest} />
            ))}
        </div>
    )
};

Spaces.propTypes = {
    spaces: array.isRequired,
    intl: intlShape.isRequired,
    setSelectedSpace: func.isRequired,
    selectedSpace: string
}
