import moment from "moment";

export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('page view:', url);
  };
  trackLogin() {
    console.log('login')
  }
  trackSignup() {
    console.log(`signs up`)
  };
  trackViewItem() {
    console.log(`view item`)
  };
  trackAddToCart() {
    console.log('add to cart')
  };
  trackBeginCheckout() {
    console.log('begin checkout')
  };
  trackAddShippingInfo() {
    console.log('add shipping info')
  };
  trackAddPaymentInfo() {
    console.log('add payment info')
  };
  trackPurchase() {
    console.log('purchase')
  };
  trackBeginListingCreation() {
    console.log('begin listing creation')
  };

  trackSubmitDetails() {
    console.log('submit details')
  };

  trackSubmitLocation() {
    console.log('submit location')
  };

  trackSubmitAvailability() {
    console.log('submit availability')
  };

  trackSubmitPricing() {
    console.log('submit pricing')
  };

  trackPublishListing() {
    console.log('publish listing')
  };

  trackProfileSettingsSavedPhoto() {
    console.log('saved photo')
  };

  trackProfileSettingsSavedAddress() {
    console.log('saved address')
  };

  trackProfileSettingsSavedBio() {
    console.log('Saved bio')
  };

  trackProfileSettingsSavedName() {
    console.log('Saved name')
  };

  trackProfileSettingsSavedPhone() {
    console.log('Saved phone number')
  };

  trackAccountSettingsSavedPaymentInfo() {
    console.log('Saved payment info')
  };

  trackAccountSettingsSavedPaymentMethod() {
    console.log('Saved payment method')
  };

};

// Google Analytics 4 (GA4) using gtag.js script, which is included in util/includeScripts.js
// Events for Intercom are also being triggered from here.
// As Intercom has different rules for meta data, only accepts some type of metadata, can't send
// array and objects. We have to send data in different way from GA.
export class GoogleAnalyticsHandler {
  trackPageView(canonicalPath, previousPath) {
    // GA4 property. Manually send page_view events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // Note 1: You should turn "Enhanced measurement" off.
    //         It attaches own listeners to elements and that breaks in-app navigation.
    // Note 2: If previousPath is null (just after page load), gtag script sends page_view event automatically.
    //         Only in-app navigation needs to be sent manually from SPA.
    // Note 3: Timeout is needed because gtag script picks up <title>,
    //         and location change event happens before initial rendering.
    if (previousPath && window.gtag) {
      window.setTimeout(() => {
        window.gtag('event', 'page_view', {
          page_path: canonicalPath,
        });
      }, 300);
    }
  }

  trackLogin() {
    if (window.gtag) {
      window.gtag("event", "login", {
        date: moment().toISOString(),
      });
    };

    if (window.Intercom) {
      window.Intercom('trackEvent', 'login', { date: moment().toISOString(), })
    };
  };

  trackSignup() {
    if (window.gtag) {
      window.gtag("event", "sign_up", {
        date: moment().toISOString(),
      });
    };

    if (window.Intercom) {
      window.Intercom('trackEvent', 'sign-up', { date: moment().toISOString(), })
    };
  }

  trackViewItem(data) {
    const { currency, value, items } = data || {};

    if (!!currency && !!value && !!items?.length && window.gtag) {
      window.gtag("event", "view_item", {
        currency,
        value,
        items,
        date: moment().toISOString()
      })
    };

    if (!!currency && !!value && !!items?.length && window.Intercom) {
      // We know items array only contains one item.
      const { item_id, item_name, item_category, price, quantity } = items[0];

      window.Intercom('trackEvent', 'view-item', {
        currency,
        value,
        item_id,
        item_name,
        item_category,
        price,
        quantity,
        date: moment().toISOString()
      });
    };
  };

  trackAddToCart(data) {
    const { currency, value, items } = data || {};
    if (!!currency && !!value && !!items?.length && window.gtag) {
      window.gtag("event", "add_to_cart", {
        currency,
        value,
        items,
        date: moment().toISOString()
      })
    };

    if (!!currency && !!value && !!items?.length && window.Intercom) {
      // We know items array only contains one item.
      const { item_id, item_name, item_category, price, quantity } = items[0];

      window.Intercom('trackEvent', 'add-to-cart', {
        currency,
        value,
        item_id,
        item_name,
        item_category,
        price,
        quantity,
        date: moment().toISOString()
      });
    };

  }

  trackBeginCheckout(data) {
    const { currency, value, items } = data || {};
    if (!!currency && !!value && !!items?.length && window.gtag) {
      window.gtag("event", "begin_checkout", {
        currency,
        value,
        items,
        date: moment().toISOString()
      })
    };

    if (!!currency && !!value && !!items?.length && window.Intercom) {
      // We know items array only contains one item.
      const { item_id, item_name, item_category, price, quantity } = items[0];

      window.Intercom('trackEvent', 'begin-checkout', {
        currency,
        value,
        item_id,
        item_name,
        item_category,
        price,
        quantity,
        date: moment().toISOString()
      });
    };
  };

  trackAddShippingInfo(data) {
    const { currency, value, items } = data;
  
    if (!!currency && !!value && !!items?.length && !!window.gtag) {
      window.gtag("event", "add_shipping_info", {
        currency,
        value,
        items,
        date: moment().toISOString()
      })
    };

    if (!!currency && !!value && !!items?.length && window.Intercom) {
      // We know items array only contains one item.
      const { item_id, item_name, item_category, price, quantity } = items[0];

      window.Intercom('trackEvent', 'add-shipping-info', {
        currency,
        value,
        item_id,
        item_name,
        item_category,
        price,
        quantity,
        date: moment().toISOString()
      });
    };
  };

  trackAddPaymentInfo(data) {
    const { currency, value, items } = data;
    if (!!currency && !!value && !!items?.length && !!window.gtag) {
      window.gtag("event", "add_payment_info", {
        currency,
        value,
        items,
        date: moment().toISOString()
      })
    };

    if (!!currency && !!value && !!items?.length && window.Intercom) {
      // We know items array only contains one item.
      const { item_id, item_name, item_category, price, quantity } = items[0];

      window.Intercom('trackEvent', 'add-payment-info', {
        currency,
        value,
        item_id,
        item_name,
        item_category,
        price,
        quantity,
        date: moment().toISOString()
      });
    };
  };

  trackPurchase(data) {
    const { transaction_id, purchaseCount, currency, value, items } = data;

    if (!!transaction_id && !!currency && !!value && !!items?.length && !!window.gtag) {
      window.gtag("event", "purchase", {
        transaction_id,
        currency,
        value,
        items,
        purchaseCount,
        date: moment().toISOString()
      })
    };

    if (!!currency && !!value && !!items?.length && window.Intercom) {
      // We know items array only contains one item.
      const { item_id, item_name, item_category, price, quantity } = items[0];

      window.Intercom('trackEvent', 'purchase', {
        transaction_id,
        purchaseCount,
        currency,
        value,
        item_id,
        item_name,
        item_category,
        price,
        quantity,
        date: moment().toISOString(),
      });
    };
  };

  trackBeginListingCreation() {
    // begin_listing_creation
    if (!!window.gtag) {
      window.gtag("event", "begin_listing_creation", {
        date: moment().toISOString(),
      })
    };

    if (window.Intercom) {
      window.Intercom('trackEvent', 'begin-listing-creation', { date: moment().toISOString(), })
    };
  };

  trackSubmitDetails() {
    if (!!window.gtag) {
      window.gtag("event", "submit_details", {
        date: moment().toISOString(),
      })
    };

    if (window.Intercom) {
      window.Intercom('trackEvent', 'submit-details', { date: moment().toISOString(), })
    };
  };

  trackSubmitLocation() {
    if (!!window.gtag) {
      window.gtag("event", "submit_location", {
        date: moment().toISOString(),
      })
    };

    if (window.Intercom) {
      window.Intercom('trackEvent', 'submit-location', { date: moment().toISOString(), })
    };
  };

  trackSubmitPricing() {
    if (!!window.gtag) {
      window.gtag("event", "submit_pricing", {
        date: moment().toISOString(),
      })
    };

    if (window.Intercom) {
      window.Intercom('trackEvent', 'submit-pricing', { date: moment().toISOString(), })
    };
  };

  trackSubmitAvailability() {
    if (!!window.gtag) {
      window.gtag("event", "submit_availability", {
        date: moment().toISOString(),
      })
    };

    if (window.Intercom) {
      window.Intercom('trackEvent', 'submit-availability', { date: moment().toISOString(), })
    };
  };

  trackPublishListing() {
    if (!!window.gtag) {
      window.gtag("event", "publish_listing", {
        date: moment().toISOString(),
      });
    };

    if (window.Intercom) {
      window.Intercom('trackEvent', 'publish-listing', { date: moment().toISOString(), })
    };
  };


  // Profile Settings
  // Profile Setting - Saved Photo
  // Profile Setting - Saved Name
  // Profile Settings - Saved Phone Number
  // Profile Setting - Saved Address
  trackProfileSettingsSavedPhoto() {
    if (window.Intercom) {
      window.Intercom('trackEvent', 'profile-settings-saved-photo', { date: moment().toISOString(), })
    };
  };

  trackProfileSettingsSavedName() {
    if (window.Intercom) {
      window.Intercom('trackEvent', 'profile-settings-saved-name', { date: moment().toISOString(), })
    };
  };

  trackProfileSettingsSavedPhone() {
    if (window.Intercom) {
      window.Intercom('trackEvent', 'profile-settings-saved-phone-number', { date: moment().toISOString(), })
    };
  };

  trackProfileSettingsSavedAddress() {
    if (window.Intercom) {
      window.Intercom('trackEvent', 'profile-settings-saved-address', { date: moment().toISOString(), })
    };
  };

  trackProfileSettingsSavedBio() {
    if (window.Intercom) {
      window.Intercom('trackEvent', 'profile-settings-saved-bio', { date: moment().toISOString(), })
    };
  };

  // Account Settings
  // Account Settings - Saved Payment Info
  // Account Settings - Saved Payment Methods
  trackAccountSettingsSavedPaymentInfo() {
    if (window.Intercom) {
      window.Intercom('trackEvent', 'account-settings-saved-payment-info', { date: moment().toISOString(), })
    };
  };

  trackAccountSettingsSavedPaymentMethod() {
    if (window.Intercom) {
      window.Intercom('trackEvent', 'account-settings-saved-payment-method', { date: moment().toISOString(), })
    };
  };

};

